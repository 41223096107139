/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 11번째 로그에서는 아웃사이더 님을 모시고, Node.js event-stream 악성 코드 주입 사건, 컨텐츠 보존 주의, 정적 웹사이트 생성기에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/183"
  }, "Seocho.rb 첫 번째 모임: 서버리스 루비 | Festa!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.iamseapy.com/archives/827"
  }, "나 몰래 컴퓨터 자원을 사용하는 블로그(사이트) | 오늘부터 seapy는")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.hani.co.kr/arti/economy/it/798812.html"
  }, "랜섬웨어 감염 웹호스팅업체 인터넷나야나, 결국 해커에 굴복 : IT : 경제 : 뉴스 : 한겨레")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://adblockplus.org/blog/kicking-out-cryptojack"
  }, "Adblock Plus and (a little) more: Kicking out Cryptojack")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.lifewire.com/google-chrome-task-manager-4103619"
  }, "How to Use the Google Chrome Task Manager")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://addons.mozilla.org/en-US/firefox/addon/tree-style-tab/"
  }, "Tree Style Tab – Get this Extension for 🦊 Firefox (en-US)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.lifewire.com/google-chrome-task-manager-4103619"
  }, "How to Use the Google Chrome Task Manager")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://arstechnica.com/information-technology/2018/11/hacker-backdoors-widely-used-open-source-software-to-steal-bitcoin/"
  }, "Widely used open source software contained bitcoin-stealing backdoor | Ars Technica")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/dominictarr/event-stream/issues/116"
  }, "I don't know what to say. · Issue #116 · dominictarr/event-stream")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/dominictarr"
  }, "dominictarr (Dominic Tarr) | Github")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://snyk.io/blog/a-post-mortem-of-the-malicious-event-stream-backdoor"
  }, "A Post-Mortem of the Malicious event-stream backdoor | Snyk")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/hugeglass/flatmap-stream"
  }, "hugeglass/flatmap-stream | Github")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/remy/nodemon/issues/1451"
  }, "Backdoored sub-dependency? flatmap-stream-0.1.1 and flatmap-stream-0.1.2 · Issue #1451 · remy/nodemon")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.npmjs.org/post/180565383195/details-about-the-event-stream-incident"
  }, "The npm Blog — Details about the event-stream incident")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/bitpay/copay/issues/9346#issuecomment-441827353"
  }, React.createElement(_components.code, null, "event-stream"), " dependency attack steals wallets from users of copay · Issue #9346 · bitpay/copay")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://thehackernews.com/2014/04/malicious-chrome-extension-hijacks.html"
  }, "Malicious Chrome Extension Hijacks CryptoCurrencies and Wallets")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Public-key_cryptography"
  }, "Public-key cryptography - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.npmjs.org/post/141577284765/kik-left-pad-and-npm"
  }, "The npm Blog — kik, left-pad, and npm")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/tric/trick2018"
  }, "tric/trick2018 | Github"), " - ioccc의 루비 버전"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/tric/trick2018/tree/master/11-tompng"
  }, "trick2018/11-tompng - christmas tree at master · tric/trick2018")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://gist.github.com/dominictarr/9fd9c1024c94592bc7268d36b8d83b3a"
  }, "dominictarr - statement on event-stream compromise")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/satsukitv/twitter-block-chain/issues/19"
  }, "ERROR AGAIN!!! · Issue #19 · satsukitv/twitter-block-chain")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bleepingcomputer.com/news/security/17-backdoored-docker-images-removed-from-docker-hub/"
  }, "17 Backdoored Docker Images Removed From Docker Hub")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.izs.me/2013/04/unix-philosophy-and-nodejs"
  }, "blog.izs.me - Unix Philosophy and Node.js")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.github.com/2017-11-16-introducing-security-alerts-on-github/"
  }, "Introducing security alerts on GitHub | The GitHub Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/guardduty/"
  }, "Amazon GuardDuty – 지능형 위협 탐지 – AWS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://kubedex.com/container-scanning/"
  }, "Container Scanning: Anchore Engine vs Aqua Microscanner vs Clair vs Dagda vs Twistlock - kubedex.com")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/nodejs/security-wg"
  }, "nodejs/security-wg: Node.js Security Working Group")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://wordpress.org/plugins/jetpack/"
  }, "Jetpack by WordPress.com | WordPress.org")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://wordpress.org/gutenberg/"
  }, "The new Gutenberg editing experience | Wordpress")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://textbundle.org/"
  }, "Welcome to TextBundle.org!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2018/7/20/17589246/data-transfer-project-google-facebook-microsoft-twitter"
  }, "Google, Facebook, Microsoft, and Twitter partner for ambitious new data project - The Verge")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://eugdpr.org/"
  }, "EUGDPR – Information Portal")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.techsuda.com/archives/6145"
  }, "도라이브 판교를 가다 - 책을 스캔하는 개발자 – 오영욱 – 테크수다")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://biz.chosun.com/site/data/html_dir/2016/03/23/2016032301651.html"
  }, "조선비즈, 국내 최고의 SW 전문 매체 '마소' 인수 - Chosunbiz")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.itcle.com/"
  }, "itcle - 페이지 읽기 오류")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.netlify.com/"
  }, "Netlify: All-in-one platform for automating modern web projects.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/premiumsupport/knowledge-center/cloudfront-serve-static-website/"
  }, "CloudFront를 사용한 정적 웹 사이트 제공")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://jekyllrb.com/"
  }, "Jekyll • Simple, blog-aware, static sites")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gatsbyjs.org/"
  }, "GatsbyJS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.netlifycms.org/"
  }, "Netlify CMS | Open-Source Content Management System")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ghost.org/ko/"
  }, "Ghost")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/axitkhurana/buster"
  }, "axitkhurana/buster: Brute force static site generator for Ghost")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/serverless/post-scheduler/"
  }, "serverless/post-scheduler: Schedule posts & content updates for static websites | Githtub")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.textcube.org/"
  }, "Textcube : Brand yourself!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.outsider.ne.kr/"
  }, "Outsider's Dev Story")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://team.daangn.com/"
  }, "Danggeun Market Inc.")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
